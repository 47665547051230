// section tabs theme

.section__proc--tabs {
  background-color: $bg-gray;
  overflow: hidden;
  .svg__accent {
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      opacity: .4;
    }
  }
  .grid-2-col-5-7 {
    grid-gap: 100px;
    padding: 8rem 5rem;
    position: relative;
    z-index: 10;
    @include media-mobile-only {
      grid-gap: 30px;
      padding: 6rem 2rem;
    }
    @include media-tablet-only {
      grid-gap: 60px;
      padding: 8rem 2rem;
    }
  }
  .tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 30px;
    @include media-mobile-only {
      padding: 0;
      line-height: 30px;
    }
    @include media-tablet-only {
      padding: 0;
      line-height: 30px;
    }
  }
  .tab-link {
    font-family: $body-font;
    font-size: 32*$pxToRem;
    color: rgba($color-white,.5);
    list-style: none;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 0 2rem;
    transition: all $transition-default;
    @include media-mobile-only {
      font-size: 20*$pxToRem;
    }
    @include media-tablet-only {
      font-size: 25*$pxToRem;
    }
    &:hover {
      color: $color-white;
    }
    &.current {
      color: $color-white;
    }
  }
  .tab-content {
    color: $color-white;
    font-size: 17*$pxToRem;
    display: none;
    opacity: 0;
    transition: all $transition-default;
    &.current {
      display: block;
      opacity: 1;
    }
  }
}