// section menus theme

.menu__procs {
  //margin-bottom: 40px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    //column-count: 2;
    li {
      list-style: none;
      display: inline-block;
      position: relative;
      margin-right: 1.5rem;
      margin-bottom: 2rem;
      a {
        font-family: $body-font;
        font-size: $font-size-body-sm;
        color: $color-black;
        font-weight: 500;
        text-transform: uppercase;
        &:hover {
          color: $color-accent;
          &:after {
            width: 40%;
          }
        }
        &:after {
          content: '';
          display: block;
          width: 100%;
          left: auto;
          right: 0;
          position: absolute;
          border-bottom: 4px solid $color-accent;
          transition: all $transition-default;
        }
      }
    }
  }
}