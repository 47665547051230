// section testimonials theme

.section__testimonials {
  .grid-3-col {
    grid-template-columns: 1fr 1fr 2fr;
    min-height: 36rem;
    @include media-below-laptop {
      grid-template-columns: 1fr;
    }
    .left {
      @include media-below-laptop {
        min-height: 10rem;
      }
    }
    .mid {
      @include media-below-laptop {
        padding: 5rem $padding-default-vw 3rem!important;
      }
    }
    .right {
      @include media-below-laptop {
        min-height: 36rem;
      }
    }
    .box {
      max-width: 300px;
      position: absolute;
      @include media-mobile-only {
        position: relative;
      }
      &.box1 {
        top: -5%;
        left: 16%;
        @include media-mobile-only {
          top: inherit;
          left: inherit;
          margin-top: 3rem;
          margin-left: auto;
          margin-right: 2rem;
        }
        @include media-tablet-only {
          top: 5%;
        }
        @include media-laptop-only {
          top: 5%;
          left: 28%;
        }
      }
      &.box2 {
        top: 35%;
        left: 0;
        @include media-mobile-only {
          top: inherit;
          left: inherit;
          margin-top: 2rem;
          margin-left: 2rem;
          margin-right: auto;
        }
        @include media-tablet-only {
          top: 50%;
          left: 6%;
        }
        @include media-laptop-only {
          top: 44%;
          left: 9%;
        }
      }
      &.box3 {
        top: 29%;
        right: 8%;
        @include media-mobile-only {
          top: inherit;
          right: inherit;
          margin-top: 2rem;
          margin-left: auto;
          margin-right: 2rem;
        }
        @include media-tablet-only {
          top: 24%;
        }
        @include media-laptop-only {
          top: 23%;
          right: 10%;
        }
      }
      &.box4 {
        top: 73%;
        right: 24%;
        @include media-mobile-only {
          top: inherit;
          right: inherit;
          margin-top: 2rem;
          margin-left: 2rem;
          margin-right: auto;
        }
        @include media-tablet-only {
          top: 70%;
          right: 20%;
        }
        @include media-laptop-only {
          top: 66%;
          right: 24%;
        }
      }
      &-container {
        background-color: $color-white;
        border: 1px solid $bg-gray;
        padding: 2rem;
        position: relative;
        .stars {
          svg {
            display: inline-block;
            fill: $color-accent;
          }
        }
        p {
          line-height: 1.4;
          margin-top: 10px;
          @include media-below-desktop {
            line-height: 1.3;
            font-size: 14*$pxToRem;
          }
        }
        &:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          z-index: 0;
          top: 100%;
          right: 1.58em;
          border: 1.02rem solid transparent;
          border-bottom: none;
          border-top-color: $color-white;
        }
        &:after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          z-index: -1;
          top: 100%;
          right: 1.5em;
          border: 1.1rem solid transparent;
          border-bottom: none;
          border-top-color: $bg-gray;
        }
      }
      span {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 15px;
        margin-right: 30px;
      }
    }
  }
}