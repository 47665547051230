// section staff theme

.section__staff {
  @include media-below-desktop {
    margin-top: 3rem;
  }
  .box-align-vertically {
    margin-top: -5rem;
    @include media-below-desktop {
      margin-top: 0;
    }
  }
  .grid-2-col-3-8 {
    @include media-below-desktop {
      grid-template-columns: 1fr;
    }
  }
  .grid {
    @include media-below-desktop {
      padding: 2rem 1rem 0;
    }
  }
  &--block {
    padding-bottom: 5rem;
    h3 {
      font-family: $body-font;
      font-size: 16*$pxToRem;
      color: $color-black;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    p {
      color: $color-gray-5;
      margin-top: 0;
    }
  }
  &--nav {
    width: 100%;
    height: 40px;
    margin-top: -1rem;
    .swiper-button-prev {
      top: 0;
      left: 45%;
      opacity: 1;
      @media screen and (max-width: 1400px) {
        left: 35%;
        display: block;
      }
    }
    .swiper-button-next {
      top: 0;
      right: 45%;
      opacity: 1;
      @media screen and (max-width: 1400px) {
        right: 35%;
        display: block;
      }
    }
  }
}