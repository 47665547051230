/* footer module: theme */

.section__footer {
  background-color: $color-gray-4;
  padding: 4rem 4rem 3rem;
  @include media-mobile-only {
      padding-bottom: 7rem;
  }    
  .menu {
    margin-bottom: 4rem;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin: 0 3%;
        list-style: none;
        display: inline-block;
        @include media-mobile-only {
          display: block;
          line-height: 1.8;
        }
        a {
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
  }
  .blurb {
    max-width: 800px;
    line-height: 1.6;
  }
  .info {
    margin-top: 0;
    line-height: 1.6;
  }
  a {
    font-weight: 400;
    &:hover {
      font-weight: 400;
    }
  }
}

.inner{
    .margin-top-250 {
        margin-top: 7.021vw;
    }
}