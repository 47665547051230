// section staff theme

.section__reviews {
    .swiper-button-disabled{
    display:none;   
}
  padding-bottom: 50px;
 @media screen and (max-width: 1279px) {
    padding-bottom: 0;
  }
  
  &__grid {
    max-width: 100% !important;
    padding-left: 80px;
   @media screen and (max-width: 1279px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      padding-right: 20px;
    }
  }
  
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 150px;
    @media screen and (max-width: 1023px) {
      gap: 100px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      gap: 50px;
    }
    
    > div {
      &:nth-of-type(1) {
        width: 100%;
        max-width: 420px;
       @media screen and (max-width: 1279px) {
          max-width: 400px;
        }
        @media screen and (max-width: 1023px) {
          max-width: 300px;
        }
        @media screen and (max-width: 767px) {
          max-width: 100%;
        }
      }
      &:nth-of-type(2) {
        flex: 1;
        min-width: 1px;
        max-width: 100%;
      }
    }
  }
  
  &__desc {
    // margin-top: 80px;
    margin-bottom: 80px;
    @media screen and (max-width: 1023px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  
  &__carousel {
    flex: 1;
    padding-bottom: 50px;
    
    .swiper {
      &-slide {
        display: flex;
        flex-direction: column;
        width: 500px !important;
        height: auto !important;
        @media screen and (max-width: 1023px) {
          width: 300px !important;
        }
        @media screen and (max-width: 767px) {
          width: calc(100% - 60px) !important;
        }
      }
    }
  }
  
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 70px 40px;
    
    @media screen and (max-width: 1023px) {
      padding: 50px 20px;

    }
    
    &-quote {
      position: absolute;
      top: 50px;
      left: 20px;
      width: 100px;
      height: 90px;
    }
    
    p {
      z-index: 2;
      position: relative;
      font-size: 1.5rem;
      font-style: italic;
      color: rgb(151, 151, 151);
      line-height: 1.667;
      @media screen and (max-width: 1023px) {
        font-size: 22px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  
  &__navigation {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30%;
      @media screen and (max-width: 767px) {
        justify-content: center;
        padding-right: 0;
      }
      
      a {
        & + a {
          margin-left: 50px;
        }
      }
    }
    
    img {
      width: auto;
    }
  }
}