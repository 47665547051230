// section schedule theme

// section schedule theme

.section__schedule {
    padding: 6rem 0;
    @include media-mobile-only {
        padding: 2rem 0;    
    }
    background-position: fixed;
    background-attachment: fixed;
    background-size: cover;
    h2{
        font-size: 3.25rem !important;
        @include media-mobile-only {
            font-size: 2.25rem !important;   
        }
        span{
            color: $color-white;
        }
    }
    .align-center{
        h2{
            &:after{
                right: 0;
                margin: 0 auto;
            }
        }
        .btn{
            margin: 0 auto;
        }
    }

}

