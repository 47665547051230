/* svg module: theme */

.svg__fill-accent {
  fill: $color-accent;
}

.svg__fill-white {
  fill: $color-white;
}

.svg__fill-gray-dark {
  fill: #272727;
}
