/* social module: theme */
// Note that magic numbers came from the design
.social {
  ul {
    list-style: none;
    display: flex;
    padding: 0;

    a {
      display: block;
      padding: 9*$pxToRem;

      svg {
        display: block;
        fill: $color-text;
        width: 22*$pxToRem;
        height: 22*$pxToRem;
        transition: fill $transition-default;
      }

      &:hover {
        svg {
          fill: $color-accent;
        }
      }
    }
  }

  @include media-mobile-only {
    ul {
      justify-content: center;
    }
  }
}
